import { inject, Injectable } from '@angular/core';
import {
  CardzGetThemeResponseModel,
  CardzRepository,
} from '@garrampa/api-services';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  cardzRepo = inject(CardzRepository);

  theme?: CardzGetThemeResponseModel;

  constructor() {
    this.cardzRepo.getTheme().subscribe(theme => {
      this.theme = theme;
    });
  }
}
